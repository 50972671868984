import Api from '../axiosInstance'
const version = '/v1'
export default {
  findAllIrj (currentPage, perPage, filter) {
    return Api.reservasi.get(`${version}/practitioner/reservasi?param0=rawat-jalan&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  findAllIgh (currentPage, perPage, filter) {
    return Api.reservasi.get(`${version}/practitioner/reservasi?param0=griya-husada&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  findAllMeningitis (currentPage, perPage, filter) {
    return Api.reservasi.get(`${version}/practitioner/reservasi?param0=meningitis&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  findAllIrm (currentPage, perPage, filter) {
    return Api.reservasi.get(`${version}/practitioner/reservasi?param0=rehab-medik&param1=${currentPage}&param2=${perPage}&param3=${filter}`)
  },
  print (id, data) {
    return Api.reservasi.get(`${version}/practitioner/reservasi/${id}?param0=cetak-ulang-apm`, data)
  },
  printUlang (id) {
    return Api.reservasi.get(`${version}/practitioner/reservasi/${id}?param0=cetak-ulang-apm`)
  },
  batal (id, data) {
    return Api.reservasi.put(`${version}/practitioner/reservasi/${id}`, data)
  },
  printapm (data) {
    return Api.reservasi.post(`${version}/practitioner/apm`, data)
  },
  printapmMigrasi (data) {
    return Api.reservasi.post(`${version}/practitioner/apmmigrasi/proses_reservasi`, data)
  },
  cekFingerMigrasi (nomorReservasi) {
    return Api.reservasi.get(`${version}/practitioner/apmmigrasi/cek_reservasi?nomor_reservasi=${nomorReservasi}`)
  },
  cekFinger (nomorReservasi) {
    return Api.reservasi.get(`${version}/practitioner/apmnew?nomor_reservasi=${nomorReservasi}`)
  },
  cekreservasiUnBridging (nomorReservasi) {
    return Api.reservasi.get(`${version}/practitioner/apmbc1/cek_reservasi?nomor_reservasi=${nomorReservasi}`)
  },
  printapmUnbridging (data) {
    return Api.reservasi.post(`${version}/practitioner/apmbc1/proses_eservasi`, data)
  },
  daftarmeningitis (data) {
    return Api.reservasi.post(`${version}/practitioner/reservasi`, data)
  },
  createRsv (data) {
    return Api.reservasi.post(`${version}/practitioner/reservasi/onsite`, data)
  }
}
