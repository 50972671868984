<template>
  <b-container fluid>
    <b-row class="mt-3">
      <b-col sm="12" id="hovert">
        <b-row>
          <b-col md="6" lg="4">
            <router-link to="bpjs" class="text-decoration-none text-dark d-block">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height hover-border">
                <template v-slot:body>
                  <b-row>
                    <div class="col-md-4">
                      <img src="../../assets/images/integrasi/logo/bpjs.svg" alt="" width="100%">
                    </div>
                    <div class="col-md-8">
                      <h4 class="mb-2 mt-2 font-weight-bold">BPJS</h4>
                      <span class="line-height-5">Badan Penyelenggara Jaminan Sosial - Kesehatan</span>
                    </div>
                  </b-row>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="4">
            <router-link to="satusehat" class="text-decoration-none text-dark d-block">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height hover-border">
                <template v-slot:body>
                  <b-row>
                    <div class="col-md-4">
                      <img src="../../assets/images/integrasi/logo/satusehat.svg" alt="" width="100%">
                    </div>
                    <div class="col-md-8">
                      <h4 class="mb-2 mt-2 font-weight-bold">SATU SEHAT</h4>
                      <span class="line-height-5">SATU SEHAT Kementerian Kesehatan - Republik Indonesia</span>
                    </div>
                  </b-row>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="4">
            <router-link to="sisrute" class="text-decoration-none text-dark d-block">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height hover-border">
                <template v-slot:body>
                  <b-row>
                    <div class="col-md-4">
                      <img src="../../assets/images/integrasi/logo/kemkes.svg" alt="" width="100%">
                    </div>
                    <div class="col-md-8">
                      <h4 class="mb-2 mt-2 font-weight-bold">SISRUTE</h4>
                      <span class="line-height-5">Sistem Rujukan Terintegrasi - Kementerian Kesehatan</span>
                    </div>
                  </b-row>
                </template>
              </iq-card>
            </router-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" lg="4">
            <router-link to="bsre" class="text-decoration-none text-dark d-block">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height hover-border">
                <template v-slot:body>
                  <b-row>
                    <div class="col-md-4">
                      <img src="../../assets/images/integrasi/logo/bsre.svg" alt="" width="100%">
                    </div>
                    <div class="col-md-8">
                      <h4 class="mb-2 mt-2 font-weight-bold">BSRE</h4>
                      <span class="line-height-5">Balai Sertifikasi Electronik - Kementerian Komunikasi dan Digital</span>
                    </div>
                  </b-row>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="4">
            <router-link to="rsonline" class="text-decoration-none text-dark d-block">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height hover-border">
                <template v-slot:body>
                  <b-row>
                    <div class="col-md-4">
                      <img src="../../assets/images/integrasi/logo/kemkes.svg" alt="" width="100%">
                    </div>
                    <div class="col-md-8">
                      <h4 class="mb-2 mt-2 font-weight-bold">RS ONLINE</h4>
                      <span class="line-height-5"> RS Online Kementerian Kesehatan - Republik Indonesia</span>
                    </div>
                  </b-row>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="4">
            <router-link to="rsonline" class="text-decoration-none text-dark d-block">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height hover-border">
                <template v-slot:body>
                  <b-row>
                    <div class="col-md-4">
                      <img src="../../assets/images/integrasi/logo/kemenkeu.svg" alt="" width="100%">
                    </div>
                    <div class="col-md-8">
                      <h4 class="mb-2 mt-2 font-weight-bold">BIOS</h4>
                      <span class="line-height-5">BLU Integrated Online System - Kementerian Keuangan</span>
                    </div>
                  </b-row>
                </template>
              </iq-card>
            </router-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" lg="4">
            <router-link to="sihepi" class="text-decoration-none text-dark d-block">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height hover-border">
                <template v-slot:body>
                  <b-row>
                    <div class="col-md-4">
                      <img src="../../assets/images/integrasi/logo/kemkes.svg" alt="" width="100%">
                    </div>
                    <div class="col-md-8">
                      <h4 class="mb-2 mt-2 font-weight-bold">SI HEPI</h4>
                      <span class="line-height-5"> Sistem Informasi Hepatitis dan PISP Kementerian Kesehatan - Republik Indonesia</span>
                    </div>
                  </b-row>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="4">
            <router-link to="sisrute" class="text-decoration-none text-dark d-block">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height hover-border">
                <template v-slot:body>
                  <b-row>
                    <div class="col-md-4">
                      <img src="../../assets/images/integrasi/logo/sakti.svg" alt="" width="100%">
                    </div>
                    <div class="col-md-8">
                      <h4 class="mb-2 mt-2 font-weight-bold">SAKTI</h4>
                      <span class="line-height-5"> Sistem Aplikasi Keuangan Tingkat Instansi - Kementerian Keuangan - Republik Indonesia</span>
                    </div>
                  </b-row>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="4">
            <router-link to="sisrute" class="text-decoration-none text-dark d-block">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height hover-border">
                <template v-slot:body>
                  <b-row>
                    <div class="col-md-4">
                      <img src="../../assets/images/integrasi/logo/kemkes.svg" alt="" width="100%">
                    </div>
                    <div class="col-md-8">
                      <h4 class="mb-2 mt-2 font-weight-bold">SISRUTE</h4>
                      <span class="line-height-5"> Sistem Rujukan Terintegrasi Kementerian Kesehatan - Republik Indonesia</span>
                    </div>
                  </b-row>
                </template>
              </iq-card>
            </router-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import IqCard from '../../components/xray/cards/iq-card'
import { xray } from '../../config/pluginInit'

export default {
  name: 'DashboardIntegrasi',
  components: { IqCard },
  data () {
    return {
      message: '',
      config: {
        dateFormat: 'Y-m-d',
        inline: true
      },
      peserta: {
        tanggal: '',
        responseTime: '',
        persent: 0,
        lastupdate: ''
      },
      antrian: {
        tanggal: '',
        responseTime: '',
        persent: 0,
        lastupdate: ''
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
  }
}
</script>

<style>
.hover-border {
  transition: all ease-in-out .1s
}

#hovert .hover-border:hover {
  border: 1px solid #089BAB;
  cursor: pointer;
}
</style>
