<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <b-alert
      :show="dismissCountDown"
      dismissible
      fade
      variant="danger"
      @dismiss-count-down="countDownChanged"
    >
      Connection failed. Please check your network connection
    </b-alert>
    <form class="mt-2 pb-5 mb-5" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        vid="username"
        name="username"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group d-flex flex-row align-items-center form-border shadow-sm rounded border">
          <div class="pl-3 pt-2 pb-2 pr-3 text-center">
            <i class="fas fa-user fa-lg"></i>
          </div>
          <div class="w-100">
            <input
              type="text"
              :class="'form-control cstm-cntrl-sign border-0 form-border-child-radius mb-0 py-4' + (errors.length > 0 ? ' is-invalid' : '')"
              id="usernameInput"
              v-model="user.username"
              placeholder="Username..."
              required
            />
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="password"
        name="Password"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group d-flex flex-row align-items-center form-border shadow-sm rounded border">
          <div class="pl-3 pt-2 pb-2 pr-3 text-center fa-lg">
            <i class="fas fa-lock"></i>
          </div>
          <div class="w-100">
            <input
              type="password"
              :class="'form-control cstm-cntrl-sign border-0 form-border-child-radius mb-0 py-4' + (errors.length > 0 ? ' is-invalid' : '')"
              id="passwordInput"
              v-model="user.password"
              placeholder="Password..."
              required
            />
          </div>
        </div>
      </ValidationProvider>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn custom-button-login py-3 py-lg-2 shadow-sm btn-login-responsive mt-2">
          <i class="fas fa-long-arrow-alt-right"></i> SIGN IN
        </button>
      </div>
    </form>
    <ModalMsgBox ref="MsgBox" />
  </ValidationObserver>
</template>

<script>
import { xray } from '../../config/pluginInit'
import { mapActions } from 'vuex'
import ModalMsgBox from '../../components/modal/ModalMsgBox.vue'

export default {
  name: 'SignInForm',
  props: ['username', 'password'],
  components: { ModalMsgBox },
  data () {
    return {
      errors: null,
      error: '',
      user: {
        username: this.username || '',
        password: this.password || ''
      },
      root: '',
      dismissSecs: 5,
      dismissCountDown: 0,
      codeCaptcha: null
    }
  },
  methods: {
    ...mapActions({ signIn: 'Auth/signIn' }),
    async onSubmit () {
      try {
        const data = { param0: this.user.username, param1: this.user.password }
        await this.signIn(data)
        this.root = xray.rootHome()
        this.$router.replace({ name: this.root })
      } catch (error) {
        const errorMsg = error.response?.data?.errors || 'An error occurred'
        this.$refs.form.setErrors(errorMsg)
        this.$refs.MsgBox.show({ type: 'error', msg: errorMsg })
      }
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    refresh () {
      localStorage.removeItem('token')
    }
  }
}
</script>

<style scoped>
.cstm-cntrl-sign {
  color: black !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  height: 38px !important;
}
.custom-button-login {
  background-color: #267BB4 !important;
  color: white !important;
}
.btn-login-responsive {
  width: 100%;
}
@media (min-width: 768px) {
  .btn-login-responsive {
    width: auto;
  }
}
</style>
