<template>
  <div>
    <div id="show-overlay"></div>
    <Loader />
    <div class="wrapper">
      <!-- Sidebar  -->
      <SideBarStyle1 :items="verticalMenu" :horizontal="horizontal" :logo="logo" @toggle="sidebarMini" />
      <div id="content-page" class="content-page" :class="horizontal ? 'ml-0' : ''">
        <!-- TOP Nav Bar -->
        <NavBarStyle1 title="Dashboard" :homeURL="{ name: 'dashboard.home' }" @toggle="sidebarMini" :search="searchPasien" :logo="logo" :horizontal="horizontal" :items="horizontalMenu">
          <template slot="responsiveRight">
            <ul class="mouse-pointer-update m-0 p-0" v-if="this.updateApp === true || this.updateApp === 'true'">
              <div class="mx-3 px-4 py-1 shadow-sm border bg-danger" @click="refresh">
                <p class="font-weight-bold mb-0">TERSEDIA UPDATE <i class="fas fa-arrow-alt-circle-down"></i></p>
              </div>
            </ul>
            <ul class="m-0 p-0">
              <div class="d-flex flex-row align-items-center mx-1 px-3 py-1">
                <div>
                  <b-img :src="require('@/assets/images/kmenkes_rsup_logo.svg')" width="170rem" fluid alt="Responsive image"></b-img>
                </div>
                <!-- <div>
                  <p class="h6 text-dark font-weight-bold mb-0">RSUP FATMAWATI</p>
                </div> -->
              </div>
            </ul>
            <ul id="search-pasien" class="mouse-pointer-update m-0 p-0 w-25" v-if="user.id_grp === 1">
              <div>
                <b-form-input type="search" class="custom-form-control" v-model="norm" @keyup.enter="searchPasienV2" placeholder="No. Rekam Medis..." size="sm"></b-form-input>
              </div>
            </ul>
            <ul class="navbar-nav ml-auto navbar-list">
              <!-- <li class="nav-item">
                <a class="search-toggle iq-waves-effect language-title" href="#"><img :src="selectedLang.image" alt="img-flaf" class="img-fluid mr-1" style="height: 16px; width: 16px;" /> {{ selectedLang.title }} <i class="ri-arrow-down-s-line"></i></a>
                <div class="iq-sub-dropdown">
                  <a class="iq-sub-card" href="javascript:void(0)" v-for="(lang, i) in langsOptions" :key="`Lang${i}`" @click="langChange(lang)">
                    <img :src="lang.image" alt="img-flaf" class="img-fluid mr-2" />{{ lang.title }}
                  </a>
                </div>
              </li> -->
              <!-- <li class="nav-item iq-full-screen">
                <a href="#" class="iq-waves-effect" id="btnFullscreen"><i class="ri-fullscreen-line"></i></a>
              </li> -->
              <li v-if="pasienAdd === true && user.id_grp === 1" class="nav-item" >
                <a href="" @click="tambahPasien" class="search-toggle iq-waves-effect">
                  <i class="fas fa-chalkboard-teacher"></i>
                  <span class="bg-warning dots"></span>
                </a>
                <!-- <div class="iq-sub-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">{{ $t('nav.allNotifications') }}<small class="badge  badge-light float-right pt-1">4</small></h5>
                      </div>
                      <a href="#" class="iq-sub-card" v-for="(item, index) in notification" :key="index">
                        <div class="media align-items-center">
                          <div class="">
                            <img class="avatar-40 rounded" :src="item.image" alt="img">
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">{{ item.name }}</h6>
                            <small class="float-right font-size-12">{{ item.date }}</small>
                            <p class="mb-0">{{ item.description.substring(0,40) + '...' }}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div> -->
              </li>
              <!-- <li class="nav-item">
                <a href="#" class="search-toggle iq-waves-effect">
                  <i class="ri-mail-open-fill"></i>
                  <span class="bg-primary count-mail"></span>
                </a>
                <div class="iq-sub-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white">{{ $t('nav.allMessages') }}<small class="badge  badge-light float-right pt-1">5</small></h5>
                      </div>
                      <a href="#" class="iq-sub-card" v-for="(item,index) in message" :key="index">
                        <div class="media align-items-center">
                          <div class="">
                            <img class="avatar-40 rounded" :src="item.image" alt="img">
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">{{ item.name }}</h6>
                            <small class="float-left font-size-12">{{ item.date }}</small>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </li> -->
            </ul>
          </template>
          <template slot="right">
            <ul class="navbar-list">
              <li>
                <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center">
                  <img class="img-fluid rounded mr-3" :src="meuser.jenis_kelamin === 1 ? imgProfile.pria : imgProfile.wanita" alt="user">
                  <div class="caption">
                    <h6 class="mb-0 line-height">{{ this.meuser.name }}</h6>
                    <span class="font-size-12">{{ $t('nav.user.available') }}</span>
                  </div>
                </a>
                <div class="iq-sub-dropdown iq-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white line-height">Hallo {{ this.meuser.name }}</h5>
                        <span class="text-white font-size-12">{{ $t('nav.user.available') }}</span>
                      </div>
                      <router-link to="/user/profile" class="iq-sub-card iq-bg-primary-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-account-box-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">Profil Anda</h6>
                            <p class="mb-0 font-size-12">Lihat detail profil</p>
                          </div>
                        </div>
                      </router-link>
                      <!-- <a href="#" class="iq-sub-card iq-bg-primary-success-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-success">
                            <i class="ri-profile-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">{{ $t('nav.user.profileEditTitle') }}</h6>
                            <p class="mb-0 font-size-12">{{ $t('nav.user.profileEditSub') }}</p>
                          </div>
                        </div>
                      </a> -->
                      <router-link to="/user/profile-edit" class="iq-sub-card iq-bg-primary-danger-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-danger">
                            <i class="ri-lock-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">Pengaturan Akun</h6>
                            <p class="mb-0 font-size-12">Kelola Akun Anda</p>
                          </div>
                        </div>
                      </router-link>
                      <!-- <a href="#" class="iq-sub-card iq-bg-primary-secondary-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-secondary">
                            <i class="ri-lock-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">{{ $t('nav.user.privacySettingTitle') }}</h6>
                            <p class="mb-0 font-size-12">{{ $t('nav.user.privacySettingSub')}}</p>
                          </div>
                        </div>
                      </a> -->
                      <div class="d-inline-block w-100 text-center p-3">
                        <a class="iq-bg-danger iq-sign-btn" href="javascript:void(0)" @click="logout" role="button">{{ $t('nav.user.signout') }}<i class="ri-login-box-line ml-2"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </template>
        </NavBarStyle1>
        <!-- TOP Nav Bar END -->
         <!-- <BreadCrumb/> -->
        <!-- <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
        </transition> -->
        <router-view/>
        <!-- <FooterStyle1>
          <template v-slot:left>
            <li class="list-inline-item"><a href="#">Privacy Policy</a></li>
            <li class="list-inline-item"><a href="#">Terms of Use</a></li>
          </template>
          <template v-slot:right>
            Copyright 2022 <a href="#">xLearnSoft</a> All Rights Reserved.
          </template>
        </FooterStyle1> -->
        <b-modal ref="modal-view" size="lg" title="Detail Log" ok-title="retry" cancel-title="Kembali" :ok-only=false :no-close-on-backdrop="true" :static="true" centered>
          <div class="mb-3">
            <iframe
              :src="iframeUrl"
              allowfullscreen
            ></iframe>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '../components/xray/loader/Loader'
import SideBarStyle1 from '../components/xray/sidebars/SideBarStyle1'
import NavBarStyle1 from '../components/xray/navbars/NavBarStyle1'
import Service from '../services/fatmahost/index'
import loader from '../assets/images/logo.png'
import { xray } from '../config/pluginInit'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Layout1',
  components: {
    Loader,
    SideBarStyle1,
    NavBarStyle1
  },
  mounted () {
    xray.triggerSet()
    xray.refreshtoken()
    this.updateRadio()
    this.updateApp = localStorage.getItem('update') === 'true' ? localStorage.getItem('update') : false
    this.user = xray.currentUser()
  },
  computed: {
    searchPasien () {
      // const utility = jwtdecode(localStorage.getItem('utility')).module.utility
      var stts = false
      // if (utility.length === 0) {
      //   stts = false
      // } else {
      //   utility.map((value) => {
      //     if (value.name === 'search') {
      //       stts = true
      //     }
      //   })
      // }
      return stts
    },
    pasienAdd () {
      // const utility = jwtdecode(localStorage.getItem('utility')).module.utility
      var stts = true
      // if (utility.length === 0) {
      //   stts = false
      // } else {
      //   utility.map((value) => {
      //     if (value.name === 'pasienadd') {
      //       stts = true
      //     }
      //   })
      // }
      return stts
    },
    ...mapGetters({
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      colors: 'Setting/colorState'
    })
  },
  watch: {
  },
  data () {
    return {
      user: {},
      norm: '',
      updateApp: null,
      iframeUrl: 'http://localhost:8080/apm',
      meuser: xray.currentUser(),
      imgProfile: {
        pria: require('../assets/images/user/14.jpg'),
        wanita: require('../assets/images/user/15.jpg')
      },
      error: '',
      horizontal: false,
      mini: false,
      darkMode: false,
      animated: { enter: 'zoomIn', exit: 'zoomOut' },
      animateClass: [
        { value: { enter: 'zoomIn', exit: 'zoomOut' }, text: 'Zoom' },
        { value: { enter: 'fadeInUp', exit: 'fadeOutDown' }, text: 'Fade' },
        { value: { enter: 'slideInLeft', exit: 'slideOutRight' }, text: 'Slide' },
        { value: { enter: 'rotateInDownLeft', exit: 'rotateOutDownLeft' }, text: 'Roll' }
      ],
      horizontalMenu: xray.menuModule(),
      verticalMenu: xray.menuModule(),
      logo: loader,
      rtl: true
    }
  },
  methods: {
    refresh () {
      this.$forceUpdate()
      window.location.reload(true)
      localStorage.removeItem('update')
    },
    searchPasienV2 () {
      if (this.norm !== '') {
        const id = this.norm
        this.$router.push({ name: 'pasien.view', params: { id } })
      } else {
        alert('KOSONG')
      }
    },
    updateRadio () {
      this.mini = this.$store.getters['Setting/miniSidebarState']
      this.horizontal = this.$store.getters['Setting/horizontalMenuState']
    },
    sidebarHorizontal () {
      this.$store.dispatch('Setting/horizontalMenuAction')
      this.updateRadio()
    },
    sidebarMini () {
      xray.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
      this.updateRadio()
    },
    rtlChange () {
      if (this.rtl) {
        this.rtlRemove()
      } else {
        this.rtlAdd()
      }
    },
    changeColor (code) {
      document.documentElement.style.setProperty('--iq-primary', code.primary)
      document.documentElement.style.setProperty('--iq-primary-light', code.primaryLight)
      if (this.darkMode) {
        document.documentElement.style.setProperty('--iq-bg-dark-color', code.bodyBgDark)
      } else {
        document.documentElement.style.setProperty('--iq-bg-light-color', code.bodyBgLight)
      }
    },
    reset () {
      this.changeColor({ primary: '#827af3', primaryLight: '#b47af3', bodyBgLight: '#efeefd', bodyBgDark: '#1d203f' })
      this.animated = { enter: 'zoomIn', exit: 'zoomOut' }
      this.light()
    },
    async logout () {
      this.$bvModal.msgBoxConfirm('Apakah Anda Yakin Ingin Keluar?', {
        title: 'Konfirmasi',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Keluar',
        cancelTitle: 'Batal',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      })
        .then(value => {
          if (value === true) {
            Service.auth.logout()
            localStorage.removeItem('token')
            localStorage.removeItem('module')
            localStorage.clear()
            // this.$router.replace({ name: 'auth1.signin' })
            this.$router.replace({ name: 'login' })
          }
        })
        .catch(err => {
          this.error = err.response
        })
    },
    langChange (lang) {
      this.langChangeState(lang)
      this.$i18n.locale = lang.value
      document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
      if (lang.value === 'ar') {
        this.rtlAdd(lang)
      } else {
        this.rtlRemove(lang)
      }
    },
    ...mapActions({
      langChangeState: 'Setting/setLangAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction',
      userId: 'User/userId'
    }),
    tambahPasien () {
      // this.$refs['modal-view'].show() kycrsupf.rsupfatmawati.id
      // const url = 'http://192.168.5.32/kycsatset/'
      const url = 'https://kycrsupf.rsupfatmawati.id'
      const options = 'width=50,height=50'
      const kyc = window.open(url, 'popupWindow', options)
      setTimeout(() => {
        kyc.close(url, 'popupWindow', options)
      }, 3000)
      // if (this.$route.name !== 'pasien.add') {
      //   this.$router.replace({
      //     path: '/pasien/add'
      //   })
      // }
    }
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");

  .mouse-pointer-update {
    cursor: pointer;
  }
  #search-pasien .custom-form-control {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    border-radius: .5rem;
  }
</style>
