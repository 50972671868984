<template>
  <b-container fluid>
    <b-row class="mt-3">
      <b-col sm="12">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <div class="mt-3 mb-3 mr-0">
              <b-row class="align-items-center">
                <b-col sm="2" class="d-flex">
                  <img src="../../../assets/images/integrasi/logo/bsre.svg" alt="Logo BPJS" width="80%">
                </b-col>
                <b-col sm="10" class="d-flex flex-column justify-content-center">
                  <h4 class="font-weight-bold">Esign - BSRE</h4>
                  <span class="line-height-5">Badan Sertifikasi Elektronik - Kementerian Komunikasi dan Digital</span>
                </b-col>
              </b-row>
            </div>
          </template>
          <template v-slot:headerAction>
            <a href="#"
              @click.prevent="$router.go(-1)"
              class="mr-3"
              v-b-tooltip.hover title="Kembali">
              <i class="fas fa-arrow-alt-circle-right fa-2x"></i>
            </a>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="3">
                <div class="border rounded-lg">
                  <tab-nav :pills="true" :vertical="true" class="text-left ml-2 mt-2 mr-2" id="v-pills-tab">
                    <tab-nav-items :active="true" id="v-pills-dashboard-tab" href="#v-pills-dashboard" ariaControls="v-pills-dashboard" role="tab" :ariaSelected="false" title="Dashboard" />
                    <tab-nav-items :active="false" id="v-pills-dashboard-dokumen-tab" href="#v-pills-dashboard-dokumen" ariaControls="v-pills-dashboard-dokumen" role="tab" :ariaSelected="true" title="Dashboard Dokumen" />
                    <span class="font-weight-bold">User</span>
                    <tab-nav-items :active="false" id="v-pills-check-status-nik-tab" href="#v-pills-check-status-nik" ariaControls="v-pills-check-status-nik" role="tab" :ariaSelected="false" title="Check Status by NIK" />
                    <tab-nav-items :active="false" id="v-pills-check-status-email-tab" href="#v-pills-check-status-email" ariaControls="v-pills-check-status-email" role="tab" :ariaSelected="false" title="Check Status by Email" />
                    <tab-nav-items :active="false" id="v-pills-registrasi-tab" href="#v-pills-registrasi" ariaControls="v-pills-registrasi" role="tab" :ariaSelected="false" title="Registrasi" />
                    <span class="font-weight-bold">Esign</span>
                    <tab-nav-items :active="false" id="v-pills-sign-pdf-nik-tab" href="#v-pills-sign-pdf-nik" ariaControls="v-pills-sign-pdf-nik" role="tab" :ariaSelected="false" title="Sign PDF by NIK" />
                    <span class="font-weight-bold">Verify</span>
                    <tab-nav-items :active="false" id="v-pills-monitoring-antrean-tab" href="#v-pills-monitoring-antrean" ariaControls="v-pills-monitoring-antrean" role="tab" :ariaSelected="false" title="Verify PDF" />
                    <tab-nav-items :active="false" id="v-pills-jadwal-dokter-tab" href="#v-pills-settings" ariaControls="v-pills-jadwal-dokter" role="tab" :ariaSelected="false" title="Verify PDF with Password" />
                  </tab-nav>
                </div>
              </b-col>
              <b-col sm="9">
                <div class="border rounded-lg">
                  <tab-content id="v-pills-tabContent" class="mt-0">
                    <tab-content-item :active="true" id="v-pills-dashboard" aria-labelled-by="v-pills-dashboard-tab">
                      <Dashboard1 class="mt-3"/>
                    </tab-content-item>
                    <tab-content-item :active="false" id="v-pills-dashboard-dokumen" aria-labelled-by="v-pills-dashboard-dokumen-tab">
                      <dashboardDokumen />
                    </tab-content-item>
                    <tab-content-item :active="false" id="v-pills-messages" aria-labelled-by="v-pills-messages-tab">
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </tab-content-item>
                      <tab-content-item :active="false" id="v-pills-settings" aria-labelled-by="v-pills-settings-tab">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    </tab-content-item>
                  </tab-content>
                </div>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import IqCard from '../../../components/xray/cards/iq-card'
import { xray } from '../../../config/pluginInit'
import Dashboard1 from '../../Dashboards/Dashboard1.vue'
// import dashboardDokumen from './Dashboard/dashboardDokumen.vue'

export default {
  name: 'DashboardBsre',
  components: {
    IqCard,
    // dashboardDokumen,
    Dashboard1
  },
  data () {
    return {
      message: '',
      config: {
        dateFormat: 'Y-m-d',
        inline: true
      },
      peserta: {
        tanggal: '',
        responseTime: '',
        persent: 0,
        lastupdate: ''
      },
      antrian: {
        tanggal: '',
        responseTime: '',
        persent: 0,
        lastupdate: ''
      }
    }
  },
  mounted () {
    xray.index()
  },
  methods: {
  }
}
</script>
