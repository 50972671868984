<template>
  <div>
    <div v-show="isLoading">
      <div id="show-overlay"></div>
      <Loader :style="{ opacity: .5 }" />
    </div>
    <div class="apm-dtl-new">
      <b-container fluid class="h-100 px-0">
        <div class="h-100">
          <div class="box-parent full-height bg-white shadow-sm border">
            <div class="body px-5 pt-4">
              <div class="d-flex flex-row align-items-center mt-4 mb-5 justify-content-between">
                <img :src="require('../../assets/images/logo_kemenkes_new.svg')" width="230rem" class="d-inline-block align-top" alt="kemenkes">
                <img :src="require('../../assets/images/myrsf1.svg')" width="100rem" class="d-inline-block align-top" alt="myrsf">
              </div>
              <div class="mb-5 px-4 py-4">
                <b-row class="justify-content-center align-items-center">
                  <b-col md="4" id="hovert">
                    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="border rounded">
                      <template v-slot:body>
                        <div>
                          <div id="login-title-v2" class="mb-4">
                            <div class="d-none d-lg-block">
                              <p class="h3 font-weight-bold kemenkes-color m-0 mb-1">RS Fatmawati</p>
                            </div>
                            <div class="d-block d-lg-none mt-4 mb-2 text-center">
                              <img :src="logokmks" class="img-fluid width-responsive" alt="logo">
                            </div>
                            <p class="m-0 p-0 text-center text-lg-left">Login to your account</p>
                          </div>
                          <sign-in-form></sign-in-form>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import SignInForm from './LoginForm.vue'

export default {
  name: 'Login',
  components: { SignInForm },
  data: () => ({
    isLoading: false,
    logokmks: require('../../assets/images/logo_kemenkes_new.svg')
  }),
  mounted () {
    this.removeToken()
  },
  methods: {
    removeToken () {
      localStorage.removeItem('token')
      localStorage.removeItem('module')
      localStorage.clear()
    }
  }
}
</script>

<style>
.apm-dtl-new .radius-top {
  border-radius: 10px 10px 0 0 !important;
}
.apm-dtl-new .btn-proses-padding {
  padding-top: 1.3em !important;
  padding-bottom: 1.3em !important;
}
.apm-dtl-new .full-height {
  min-height: 100vh !important;
  background-image: url('../../assets/images/bg-pattern.png');
  background-repeat: no-repeat;
  animation: bg-animation 50s linear infinite alternate;
}
@keyframes bg-animation {
  0% { background-size: 100%; }
  50% { background-size: 150%; }
  100% { background-size: 100%; }
}
.apm-dtl-new .custom-input-style {
  border: 1px solid rgb(235, 235, 235);
  border-radius: 10px;
  width: 100% !important;
  height: 60px !important;
  padding: 10px;
  font-size: 17px;
  font-weight: bold;
}
#login-title-v2 .kemenkes-color {
  color: #267BB4;
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
</style>
